import CONSTANTS from "config/constants";

const { TITLE } = CONSTANTS;

const es = {
  title: "Simple Commerce",
  home: {
    metaTitle: TITLE,
    metaDescription:
      "Los productos de mascotas que tus clientes están buscando."
  },
  footer: {
    copyright: "© Pure Nature 2022. ",
    reserved: "Todos los derechos reservados.",
    developed: "Desarrollado por ",
    trade: "Trade",
    faq: "Preguntas frecuentes",
    navigation: {
      profile: "Perfil",
      settings: "Ajustes",
      privacy: "Políticas de privacidad",
      terms: "Términos y condiciones",
      legalDocs: "Documentos legales y Manejo de datos",
      dangerousZones: "Zonas de entrega sin cobertura"
    }
  },
  navigation: {
    shopping: "Compras",
    discover: "Descubre",
    loyalty: "Lealtad",
    coupons: "Cupones",
    profile: "Perfil",
    notification: "Notificaciones",
    viewCategories: "Ver Categorías",
    categories: "Categorías",
    user: "Usuario"
  },
  catalogues: {
    delivery: "Entrega",
    pickup: "Retiro",
    other: "Otro"
  },
  common: {
    units: " p/u",
    preferences: "Preferencias",
    workflows: "Modalidad de compra",
    apply: "Aplicar",
    buyingIn: "Comprando en",
    catalogue: "Catálogo",
    continue: "Continuar",
    retry: "Reintentar",
    send: "Enviar",
    confirm: "Confirmar",
    cancel: "Cancelar",
    edit: "Editar",
    soon: "¡Prepárate, esta funcionalidad estará disponible próximamente!",
    goToPay: "Ir a pagar",
    pay: "Pagar",
    warning: "¿Estás seguro que quieres salir?",
    add: "Añadir",
    update: "Actualizar",
    accept: "Aceptar",
    save: "Guardar",
    change: "Cambiar",
    delete: "Eliminar",
    keep: "No eliminar",
    goBack: "Regresar",
    back: "Atrás",
    tryAgain: "Volver a intentarlo",
    enter: "Ingresar",
    search: "Buscar",
    unselected: "Sin seleccionar",
    hello: "Hola",
    ourProducts: "Nuestros productos",
    viewAll: "Ver todos",
    repeat: "Repetir",
    view: "Ver",
    brand: "Marca",
    presentation: "Presentación",
    seeMore: "Ver más",
    seeLess: "Ver menos",
    label: "Etiqueta",
    other: "Otro",
    local: "Local",
    winery: "Bodega",
    deliveryAddress: "Dirección de entrega",
    close: "cerrar",
    deUnaPayRejected: "Tu pago no ha sido procesado o ha sido rechazado",
    buyNow: "Comprar ahora",
    account: "Crear cuenta",
    logOut: "Cerrar sesión",
    seeCart: "Ver carrito",
    deleteAccount: "Eliminar cuenta",
    iva: "Todos los precios tienen IVA incluido"
  },
  forms: {
    name: "Nombre",
    lastName: "Apellido",
    nameAndLastName: "Nombre y apellido",
    documentType: "Tipo de documento",
    document: "Documento",
    email: "Correo",
    emailAddress: "Correo electrónico",
    emailPlaceholder: "hola@email.com",
    address: "Calle Principal",
    secondaryAddress: "Calle secundaria",
    phoneNumber: "Número de contacto",
    password: "Contraseña",
    passwordPlaceholder: "************",
    cardNumber: "Número de la tarjeta",
    cardHolder: "Nombre de la tarjeta",
    expirationDate: "Fecha de vencimiento",
    cvv: "CVV",
    dateOfBirth: "Fecha de nacimiento",
    dateOfBirthPlaceholder: "DD / MM / AAAA",
    passport: "Pasaporte",
    ci: "Cédula",
    commerceType: "Tipo de comercio",
    businessName: "Razón social",
    saveChanges: "Guardar cambios",
    reference: "Referencia",
    addressPlaceholder: "Depto./Oficina/Piso",
    referencePlaceholder: "Casa blanca con portón negro",
    noteLabel: "Agrega una nota de entrega (opcional)",
    notePlaceholder:
      "Indicaciones de entrega, persona que lo recibe, horarios, etc.",
    saveAddress: "Guardar dirección",
    validDateOfBirth: "La fecha ingresada no es válida",
    autoservice: "Autoservicio",
    desktop: "Mostrador",
    eCommerce: "E-Commerce",
    birthdayError: "Seleccione una fecha correcta"
  },
  errors: {
    cardFormValues:
      "Por favor, complete todos los campos del formulario de la tarjeta",
    additionalInfo:
      "Por favor, complete todos los campos del formulario de información adicional",
    notAuthenticated: "No estás autenticado",
    phoneIsRequired: "El número de teléfono es requerido",
    mainStreetIsRequired: "La calle principal es requerida",
    countryCodeIsRequired: "El código de país es requerido",
    cardOwnerIsRequired: "El titular de la tarjeta es requerido",
    cardDocumentIsRequired: "El documento de la tarjeta es requerido",
    cardIsNotSelected: "No tiene alguna tarjeta seleccionada",
    generic: "Algo salió mal",
    errorMessage:
      "Si el problema sigue comunícate con soporte y te ayudaremos a solucionarlo.",
    firebase: {
      auth: {
        wrongPassword: "Los datos son inválidos",
        userNotFound:
          "No existe un registro de usuario con el correspondiente identificador provisto",
        tooManyRequests:
          "Demasiadas solicitudes. El acceso a esta cuenta se ha inhabilitado temporalmente",
        emailAlreadyInUse: "El correo electrónico provisto ya está en uso",
        networkRequestFailed:
          "Ha ocurrido un error de conexión, intenta nuevamente",
        operationNotAllowed:
          "El proveedor está deshabilitado en tu proyecto de firebase",
        accountExistsWithDifferentCredential:
          "An account already exists with the same email address but different sign-in credentials",
        invalidActionCode: "El link usado es inválido",
        userDisabled: "Su cuenta ha sido deshabilitada",
        invalidEmail: "Ingresa un correo electrónico válido",
        weakPassword: "Contraseña débil"
      }
    },
    createAddress: "Ocurrió un problema al guardar la dirección de envío",
    updateAddress: "Ocurrió un problema al actualizar la dirección de envío"
  },
  categories: {
    title: "Categorías",
    empty: "Estamos cambiando el menú",
    emptyProducts: "No hay productos",
    emptyDescription:
      "Al parecer no hay productos aquí, intenta explorando otras categorías",
    emptyButton: "Explorar",
    error: "Al parecer algo falló",
    errorDescription:
      "Hubo un error al cargar la página, intenta recargando la página o vuelve luego",
    errorButton: "Explorar más productos",
    promotion: "Promociones"
  },
  coupons: {
    points: "Puntos acumulados",
    metaTitle: "Cupones",
    detailTitle: "Cupones",
    add: "Agregar",
    apply: "Aplicar",
    enterCoupon: "Ingresar cupón",
    dateValid: "Válido hasta",
    availablePoints: "Puntos disponibles",
    usePoints: "Usar puntos"
  },
  productsSearch: {
    metaTitle: "Descubre",
    result: "Resultados de",
    title: "Recomendados para ti",
    emptyProducts: "No encontramos resultados para",
    emptyDescription: "Te invitamos a intentar con una palabra distinta",
    explore: "Explorar otros productos",
    error: "Al parecer algo falló",
    errorDescription:
      "Hubo un error al cargar la página, intenta recargando la página o vuelve luego",
    moreProducts: "Ver más productos",
    notResult: "No encontramos resultados para",
    anotherWord: "¿Te gustaría intentar con otra palabra?",
    recommendedWord: "que tal si pruebas con “Cuidado”"
  },
  profile: {
    metaTitle: "Usuario",
    profileHeader: {
      greetings: "¡Hola!",
      edit: "Editar cuenta"
    },
    profileMembership: {
      title: "Club Pure Nature",
      points: "Continúa acumulando puntos",
      otherLevels: "Otros Niveles",
      newMember: "Ya eres parte del Club Pure Nature",
      levelCard: {
        purchases: "compras",
        button: "Ver membresía"
      },
      currentLevel: {
        level: "Nivel",
        bronze: "Bronce",
        history: "Historial de movimientos",
        current: "Actual",
        availableBenefits: "Beneficios disponibles",
        enjoyBenefits: "Disfruta de los beneficios de tu nivel actual",
        purchases: "Compras al año",
        silver: "Plata",
        gold: "Oro",
        benefits: "Beneficios"
      },
      noMembership: {
        accumulate: "Acumula",
        points: "Puntos",
        firstPurchase: "Haz tu primera compra y comienza a acumular puntos",
        join: "Únete ahora",
        benefits: "Conoce los beneficios"
      }
    },
    profileAddress: {
      title: "Dirección de entrega",
      addresses: "Direcciones",
      add: "Añadir nuevas direcciones",
      maxAddress: {
        possible: "Solo es posible tener",
        active: "direcciones activas",
        limitation: "Límite de datos de direcciones superado"
      },
      empty: {
        title: "Guarda tus direcciones",
        description:
          "Agrega una dirección de entrega para realizar tu primera compra"
      }
    },
    profileCoupons: {
      title: "Cupones",
      addCoupon: "Ingresa tu cupón",
      add: "Agregar",
      successRedeemCoupon: "Cupón redimido con éxito",
      successApplyCoupon: "Cupón aplicado con éxito",
      errorRedeemCoupon:
        "No se pudo añadir el cupón a la billetera, compruebe que el cupón sea valido o no haya sido canjeado anteriormente",
      available: "Disponibles para canjear",
      expired: "Vencidos",
      empty: {
        message: "No existe billetera de cupones disponibles para tu usuario",
        title: "Añade cupones",
        description:
          "Con tus cupones podrás obtener descuentos y muchos más beneficios"
      }
    },
    profileUser: {
      title: "Editar mi cuenta",
      editPhoto: "Editar foto",
      form: {
        commerce: "Tipo de comercio",
        genre: "Género",
        male: "Masculino",
        female: "Femenino",
        ruc: "RUC",
        invalidPhone: "El teléfono ingresado no es válido",
        updateErrorData:
          "Se produjo un error al intentar actualizar los datos del usuario",
        saveErrorData:
          "Se produjo un error al intentar guardar los datos del usuario",
        savedChanges: "Cambios guardados con éxito",
        imageError: "Ocurrió un problema al subir la imagen seleccionada",
        invalidImage: "La imagen seleccionada no es válida",
        imageMaxSize:
          "El tamaño de la imagen seleccionada no debe sobrepasar 1 Mb"
      },
      linking: {
        title: "Conecta tu cuenta con tus redes",
        connectFb: "Conectar con Facebook",
        connectGoogle: "Conectar con Google",
        disconnectFb: "Desconectar de Facebook",
        disconnectGoogle: "Desconectar de Google"
      },
      security: "Seguridad",
      updatePassword: {
        title: "Cambiar contraseña",
        change: "Cambia tu contraseña",
        current: "Contraseña actual",
        new: "Ingresa la nueva contraseña",
        repeat: "Repite la nueva contraseña",
        error: "Las contraseñas no coinciden",
        form: {
          success: "Cambio exitoso",
          updated: "Contraseña actualizada correctamente",
          error: "No fue posible actualizar la contraseña"
        }
      }
    },
    profileOrders: {
      title: "Historial de compras",

      empty: {
        title: "Aún no tienes pedidos en curso",
        description: "Empieza a hacer tus compras",
        buttonTitle: "Empezar a comprar"
      },
      error: {
        title: "No existe la orden en esta sesión",
        description:
          "No existe información para la orden especificada en este navegador"
      },
      orderInfo: {
        title: "Detalle de orden",
        resume: "Resumen de la orden",
        information: "Información de transacción",
        hour: "Hora",
        reference: "Referencia",
        status: "Estado",
        deliveryAddress: "Dirección de entrega",
        store: "Tienda",
        billing: "Datos de facturación",
        paymentMethod: "Método de pago",
        date: "Fecha"
      },
      orderList: {
        date: "Fecha",
        orderBy: "Ordenar por"
      },
      orderPreview: {
        days: "días",
        hours: "horas",
        lastPurchases: "Últimos pedidos",
        previous: "Pedidos anteriores",
        pendding: "Pedidos pendientes",
        deliveryDate: " 72 horas"
      },
      orderStatus: {
        orderInProcess: "Pedido en proceso",
        deliveryDate: "Tu pedido se entregará alrededor de 72 horas",
        delivered: "¡Tu pedido ha sido entregado!",
        rateOrder: "Califica tu orden",
        thankYouForRating: "Gracias por calificar tu orden"
      },
      rateOrder: {
        title: "Califica tu pedido",
        support: "Soporte",
        rate: "Calificar",
        order: "Orden #",
        howWasYouOrder: "¿Cómo estuvo tu orden?",
        genericError: "¿Algo salió mal?",
        whatWentWrong: "Coméntanos que salió mal",
        poorQuality: "Mala calidad del producto",
        notWhatAskedFor: "No fué lo que pedí",
        missingOrder: "Algo faltó en mi orden",
        deliveryTrouble: "Problemas con el repartidor",
        badPresentation: "Mala presentación",
        others: "Otros",
        whatYouLikedTheMost: "¿Qué fue lo que más te gustó?",
        whatYouLiked: "Coméntanos que te gustó",
        easyToPlaceOrder: "Fue muy rápido ",
        excellentPresentation: "Excelente presentación ",
        excellentProduct: "Excelente sabor",
        foundEverything: "Buena atención ",
        feedback: "¡Gracias por tus comentarios!",
        comment: "Comentario",
        commentPlaceholder: "Agrega un comentario"
      }
    },
    profileStores: {
      title: "Locales",
      address: "Dirección: ",
      phone: "Teléfono: ",
      opens: "Abre: ",
      open: "Abierto",
      closed: "Cerrado"
    },
    profileBilling: {
      title: "Datos de facturación",
      newBillingData: "Nuevos datos de facturación",
      addBillingData: "Añadir nuevos datos de facturación",
      editBillingData: "Editar datos de facturación",
      deleteBillingData: "¿Deseas proceder a eliminar?",
      maxBilling: {
        possible: "Solo es posible tener",
        active: "datos de facturación activos",
        limitation: "Límite de datos de facturación superado"
      },
      empty: {
        title: "Guarda tus datos",
        description: "Agrega tus datos de facturación para comprar más rápido"
      },
      modal: {
        names: "Nombre y apellido / Razón social",
        ruc: "Número de RUC",
        phone: "Número de contacto",
        useAsDefault: "Usar como predeterminada",
        saveData: "Guardar datos",
        invalidPhone: "El teléfono ingresado no es válido",
        updateErrorData: "Ocurrió un problema al actualizar los de facturación",
        saveErrorData: "Ocurrió un problema al guardar los de facturación",
        deleteErrorData:
          "Se produjo un error al intentar eliminar los datos de facturación",
        deleteInformation:
          "Una vez eliminado no podrás recuperar esta información"
      },
      form: {
        natural: "Persona natural",
        legal: "Persona jurídica"
      }
    },
    profileNotification: {
      title: "Notificaciones",
      enableEmailNotifications:
        "Recibir notificaciones del estado de mis pedidos vía mail",
      empty: {
        title: "No tienes ninguna notificación",
        description: "Recibirás notificaciones al tener ordenes pendientes"
      }
    },
    profilePolitics: {
      title: "Configuración de privacidad",
      description:
        "Recibe notificaciones en tu correo sobre novedades y ofertas",
      accept: "Habilitar modo oscuro"
    },
    profileSettings: {
      title: "Configuraciones",
      enableDarkMode: "Habilitar modo oscuro"
    },
    profileTerms: {
      title: "Términos legales",
      politics: "Políticas de privacidad y uso de datos",
      terms: "Términos y condiciones",
      faq: "Preguntas frecuentes",
      authorizations: "Autorizaciones y derechos",
      accounts: "Cuentas, contraseñas y seguridad",
      duties: "Sus derechos",
      privacy: "Políticas de privacidad de datos",
      toppings: "Coberturas",
      politicsInfo:
        "La información que EL USUARIO proporcione a PET GOURMET a través de la Plataforma se rige por la Política de Privacidad del mismo. Mediante el uso de la Plataforma, EL USUARIO reconoce y acepta que cualquier información que usted transmita a PET GOURMET en cualquier forma y por cualquier motivo, no será tratada como confidencial o privada. Además, EL USUARIO y acepta que cualquier idea, conceptos, técnicas, procedimientos, métodos, sistemas, diseños, gráficos, u otros materiales que usted transmita a PET GOURMET pueden ser utilizados por PET GOURMET en cualquier lugar, en cualquier momento y por cualquier motivo.",
      politicsContentInfo:
        "Parte de la funcionalidad de la Plataforma, incluyendo el pago móvil, los servicios basados en la localización y funcionalidad, y el acceso de la libreta de direcciones del usuario y la biblioteca de fotos puede requerir la transmisión de la información proporcionada por el usuario, incluyendo, sin limitación, nombres, nombres de usuario y contraseñas, direcciones, dirección, fotos, información financiera (como números de tarjetas de crédito) e-mail y / o localización GPS. Si el usuario utiliza la funcionalidad de dicha Plataforma, el usuario consiente la transmisión de la información del usuario a PET GOURMET y / o sus agentes y autoriza a PET GOURMET y / o sus agentes para registrar, procesar y almacenar la información del usuario como sea necesario para la funcionalidad de la Plataforma y para los propósitos descritos en la Política de Privacidad de PET GOURMET .",
      termsInfo:
        "El uso del sitio web y la aplicación móvil en los que estos términos residen (colectivamente, la “Plataforma”), y las características de esta plataforma están sujetos a estas Condiciones de Uso (este “Acuerdo”), que establece un acuerdo legalmente vinculante entre Usted (para efectos del presente instrumento se lo denominará como “EL USUARIO”) y PET GOURMET S.A. bajo su nombre comercial “PET GOURMET ” (para efectos del presente instrumento se lo denominará simplemente como “PET GOURMET ”). Por favor, lea este Acuerdo cuidadosamente antes de utilizar esta Plataforma. Si no está de acuerdo con los términos contenidos en este Acuerdo, entonces no utilice la Plataforma. El uso de la Plataforma constituye el reconocimiento de su aceptación de este Acuerdo. Esto incluye, pero no se limita a, la realización de una transacción electrónica y la aceptación de la renuncia de garantías, indemnizaciones y recursos exclusiones y limitaciones. Usted reconoce que ha leído y acepta estar obligado por este Acuerdo a cumplir con todas las leyes, reglamentos y / o normas aplicables en relación con el uso de la Plataforma.",
      termsContentInfo:
        "PET GOURMET puede actualizar esta plataforma y el presente Acuerdo cada vez que lo creyera necesario y cada cierto tiempo. El uso de esta plataforma después de que PET GOURMET notifica una actualización de la plataforma o de este Acuerdo constituye la aceptación de dichos cambios de forma prospectiva desde la fecha de dichos cambios.",
      termsMoreContentInfo:
        "PET GOURMET puede, a su sola discreción y en cualquier momento, suspender esta Plataforma o cualquier parte de la misma, con o sin previo aviso, o impedir su uso con o sin previo aviso. Usted está de acuerdo que no tiene ningún derecho sobre y en esta Plataforma y, PET GOURMET no tendrá ninguna responsabilidad con el USUARIO si se interrumpe esta Plataforma o si su capacidad de acceder a la plataforma termina.",
      termsPlatformInfo:
        "No puede utilizar la Plataforma para finalidades ilegales o prohibidas por este acuerdo. Su acceso a la Plataforma puede ser terminado inmediatamente a discreción única de PET GOURMET , con o sin aviso, si usted no cumple con alguna disposición de este acuerdo y/o condiciones adicionales.",
      authorizationsInfo:
        "EL USUARIO otorga su consentimiento libre, explícito, inequívoco e informado para que PET GOURMET trate sus datos personales y los almacene en una base de datos que será utilizada para promocionar lanzamiento de nuevos productos o actividades de mercadeo de PET GOURMET, EL USUARIO al usar esta plataforma acepta ceder su información para la base de datos.",
      authorizationsContentInfo:
        "EL USUARIO autoriza expresamente para que se utilicen los datos personales por cualquier medio o forma, ya sea física o inalámbrica.",
      authorizationsMoreContentInfo:
        "Así mismo, EL USUARIO autoriza a PET GOURMET a tratar sus datos personales para cumplir sus obligaciones y compromisos con en esta plataforma, quedando autorizados a almacenarlos, transferirlos, nacional o internacionalmente, cederlos o compartirlos sin previa autorización por escrito de parte del USUARIO. Estos datos serán almacenados, por PET GOURMET quien declara sobre la existencia de una base de datos y que esta está resguardada debidamente bajo las medidas de seguridad adecuadas para proteger los datos personales entregados.",
      authorizationsSecurity:
        "PET GOURMET informará acerca de violaciones a las seguridades implementadas para el tratamiento de datos personales. EL USUARIO tendrá derecho a revocar su consentimiento y a ejercer todos los derechos consagrados en la Ley Orgánica de Protección de Datos Personales. Pasados diez (10) años desde la obtención de los datos personales, PET GOURMET se compromete a eliminar los datos personales de manera segura y sistémica, sin quedarse con ningún dato a su favor para ningún fin específico.",
      authorizationsUserData:
        "EL USUARIO declara que los datos personales entregados no son erróneos o inexactos, pero en caso de serlos, podrán ser corregidos a petición del USUARIO. EL USUARIO está garantizado de la existencia y forma en que pueden hacerse efectivos sus derechos de acceso, eliminación, rectificación y actualización, oposición, anulación, portabilidad, limitación del tratamiento y a no ser objeto de una decisión basada únicamente en valoraciones automatizadas, así como la forma de realizar reclamos ante el responsable del tratamiento de datos personales y la Autoridad de Protección de Datos Personales." +
        "EL USUARIO declara que no tiene obligaciones ni derechos de exclusividad con personas naturales o jurídicas que limiten el ejercicio de estas condiciones por lo que eximen de cualquier responsabilidad o reclamo de cualquier tercero por este hecho.",
      authorizationsNameUser:
        "EL USUARIO autoriza que su nombre pueda ser publicado en medios de comunicación de PET GOURMET.",
      accountsInfo:
        "Ciertas áreas de la Plataforma pueden requerir registro o de lo contrario pueden pedir, proporcionar información para participar en ciertas funciones o acceder a determinados contenidos. Si decide no proporcionar dicha información, es posible que no pueda acceder a determinados contenidos o participar en ciertas características de la Plataforma o cualquier característica en absoluto.",
      accountsContentInfo:
        "EL USUARIO entiende que ciertas ofertas, servicios y características que pueden estar disponibles en la Plataforma pueden estar sujetos a términos y condiciones específicas adicionales. Esto puede incluir programas de lealtad y recompensas. En el caso de cualquier conflicto entre este Acuerdo y cualquiera de dichos términos y condiciones específicos, los términos y condiciones específicos controlarán. También entiende y acepta que determinadas ofertas, servicios y funciones pueden estar disponibles en la versión página web de la Plataforma y no en la versión aplicación móvil de la Plataforma, y viceversa.",
      accountsMoreContentInfo:
        "Además, EL USUARIO es el único responsable de cualquiera y todas las actividades que ocurran bajo su cuenta, incluidos los pagos móviles, todas las transacciones y otras actividades llevadas a cabo con el dispositivo, o tarjeta de pago almacenada, ya sea o no autorizado. EL USUARIO se compromete a notificar a PET GOURMET inmediatamente de cualquier uso no autorizado de su cuenta. Además, acepta no enviar por correo electrónico, publicar o difundir cualquier ID de usuario, contraseña u otra información que le proporciona acceso a la Plataforma. PET GOURMET no se hace responsable de cualquier pérdida que pueda sufrir como resultado de que otra persona utilice su contraseña o cuenta, ya sea con o sin su conocimiento. PET GOURMET no será responsable de ninguna pérdida o robo de información de usuarios transmitidos desde o almacenados en un ordenador o dispositivo o de las transacciones no autorizadas o fraudulentas relacionadas con la Plataforma.",
      accountUserAccept:
        "El USUARIO acepta que los proveedores de PET GOURMET y PET GOURMET pueden recopilar y utilizar los datos técnicos y la información relacionada, incluyendo, pero no limitado a la información técnica sobre su dispositivo, geolocalización, fecha y hora de la plataforma de acceso, sistema y software de aplicación, y periféricos, que se reúne periódicamente para, entre otras cosas, facilitarle el suministro de actualizaciones de software, soporte de producto y otros servicios en relación con la Plataforma. EL USUARIO concede a PET GOURMET el permiso para utilizar esta información para mejorar sus productos o para proporcionarle a usted servicios o tecnologías, así como para proporcionar contenido de la publicidad en la que PET GOURMET cree que podría estar interesado, incluyendo el trabajo con terceros que proporcionan contenido de publicidad dirigida.",
      accountPlatform:
        "A través de la plataforma, usted puede ser capaz de ordenar y / o pagar a PET GOURMET por productos o servicios. Para ser capaz de pedir y pagar por este tipo de productos o servicios, debe proporcionar cierta información relevante para su operación, incluyendo, sin limitación, el número de su tarjeta de crédito o de débito, la fecha de vencimiento de su tarjeta de crédito o de débito, el nombre de su tarjeta de crédito o tarjeta de débito y su dirección de facturación. También puede suministrar la información de envío. Usted representa y garantiza que tiene el derecho legal de uso de cualquier tarjeta bancaria u otro método de pago utilizado en conexión con cualquier transacción. Mediante la presentación de dicha información, usted otorga a PET GOURMET y / o los terceros el derecho de proporcionar y transmitir dicha información a los efectos de facilitar la realización de las transacciones iniciadas por usted o en su nombre. La verificación de la información puede ser requerida antes de la confirmación o la realización de cualquier transacción.",
      dutiesInfo:
        "Los datos personales no serán transferidos a terceras personas, y PET GOURMET S.A.(“PET GOURMET ”), no comercializa, vende ni alquila su base de datos a terceros.",
      dutiesContentInfo:
        "El Usuario podrá solicitar gratuitamente, el acceso, rectificación, actualización, inclusión o modificación, cancelación y cualquier otra acción relativa a su información personal en conformidad con la Constitución de la Republica del Ecuador a PET GOURMET S.A.(“PET GOURMET ”).",
      dutiesMoreContentInfo:
        "PET GOURMET S.A.(“PET GOURMET ”) retiene la información del usuario el tiempo que mantenga activa su cuenta, en caso que desee el Usuario eliminar su cuenta, deberá seguir el siguiente proceso:",
      dutiesAccount:
        "• Enviar la solicitud de eliminación de cuenta al correo PET GOURMET \n" +
        "• El Usuario recibirá un correo señalando que en un lapso máximo de 10 días hábiles su cuenta estará eliminada. \n" +
        "• Antes de la fecha establecida el Usuario recibirá un correo en el cual se mostrará todos los datos que han sido eliminados",
      dutiesEliminatedData:
        "Una vez finalizado el procedimiento de eliminación de sus datos, PET GOURMET S.A. (“PET GOURMET ”), no tendrá registro alguno en las bases da datos, por lo que solo quedará los pedidos que ha realizado el Usuario para efectos de análisis de prevención de fraudes y datos estadísticos internos de la empresa.",
      dutiesSubtitle:
        "REPRESENTACIÓN, RENUNCIA DE GARANTÍAS Y LIMITACIONES DE RESPONSABILIDAD ENLACES A LAS PLATAFORMAS Y SERVICIOS DE TERCEROS",
      dutiesPlatform:
        "La Plataforma puede proporcionar conectividad o enlaces a otros servicios de terceros, sitios web, aplicaciones, software y otros contenidos de terceros proveedores como los interlocutores sociales, los medios de comunicación, proveedores de servicios móviles y desarrolladores de aplicaciones de software de terceros ('Servicios de Terceros'). La Plataforma puede permitirle agregar / configurar ciertos servicios de terceros en el dispositivo. La Plataforma también le puede permitir que inicie su cuenta a través de Facebook u otra cuenta de medios de comunicación social. PET GOURMET no tiene ningún control sobre esto, no representa o da garantías de ningún tipo acerca de cualquiera de los servicios de terceros a los que acceda, no es responsable de la disponibilidad de dichos Servicios de terceros, y no los respalda ni es responsable de cualquier contenido u otros materiales disponibles en dichos Servicios de terceros. Su uso de los servicios de terceros puede estar sujeto a términos adicionales, incluyendo los términos de licencia de software, de dichos terceros.",
      dutiesServices:
        "Si utiliza Servicios de terceros, usted acepta que es consciente de que la cuenta y otra información personal en manos de esos terceros pueden ser transmitidos a PET GOURMET y se almacenan en los servidores de PET GOURMET y / o aplicaciones ubicadas en los Estados Unidos y en otros lugares. Usted entiende y acepta que las empresas que prestan los servicios de terceros pueden acceder, utilizar y compartir cierta información sobre usted. EL USUARIO entiende y acepta que PET GOURMET no es responsable de estas empresas, o el uso de su información. El uso de los servicios de terceros es bajo su propio riesgo.",
      dutiesContact:
        "En el caso de una queja o preocupación con respecto a este Acuerdo o la plataforma; o para más información, póngase en contacto con PET GOURMET https://www.purenature-pets.com.",
      privacyInfo:
        "PET GOURMET S.A.(“PET GOURMET ”) respeta su derecho de privacidad. Esta política resume qué información personal podemos recoger, almacenar y manejar y otros temas importantes relacionados con su privacidad y protección de datos." +
        "Es política de PET GOURMET S.A.(“PET GOURMET ”) cumplir con todas las leyes de privacidad y de protección de datos vigentes. Este compromiso refleja el valor que le damos al hecho de obtener y conservar la confianza de nuestros clientes." +
        "Esta Política se aplica al sitio web de Internet www.purenature.com.ec; también a toda la información personal que PET GOURMET S.A.(“PET GOURMET ”) pueda obtener de cualquier otro modo: (1) a través de la compra de nuestros productos." +
        "En la web de PET GOURMET www.purenature.com.ec, acorde a la funcionalidad que acceda el cliente en dicha web, se recolecta la siguiente información:",
      privacyContentInfo:
        "• El momento de realizar el pedido se compartirá los datos de ubicación \n" +
        "• Datos personales: RUC, Razón Social, correo electrónico y número telefónico \n" +
        "• Datos de acceso: Nombre de usuario, contraseña.",
      privacyMoreContentInfo:
        "PET GOURMET S.A.(“PET GOURMET ”) retiene la información del usuario el tiempo que mantenga activa su cuenta, en caso que desee el Usuario eliminar su cuenta, deberá seguir el siguiente proceso:",
      privacyMailInfo:
        "Enviar la solicitud de eliminación de cuenta al correo PET GOURMET \n" +
        "El Usuario recibirá un correo señalando que en un lapso máximo de 10 días hábiles su cuenta estará eliminada. \n" +
        "Antes de la fecha establecida el Usuario recibirá un correo en el cual se mostrará todos los datos que han sido eliminados",
      privacyDataInfo:
        "Una vez finalizado el procedimiento de eliminación de sus datos, PET GOURMET S.A.(“PET GOURMET ”), no tendrá registro alguno en las bases de datos, por lo que solo quedará los pedidos que ha realizado el Usuario para efectos de análisis de prevención de fraudes y datos estadísticos internos de la empresa.",
      protectedData: "Protección y uso de datos personales"
    },
    profileSupport: {
      title: "Soporte",
      subTitle: "Contáctanos al",
      email: "Correo"
    },
    profileQuestions: {
      title: "Preguntas frecuentes",
      question1: "¿Los precios de los productos incluyen IVA?",
      answer1:
        "¡Sí! Todos nuestros productos ya incluyen el Impuesto al Valor Agregado (IVA), para que estes al tanto del monto real que vas a pagar al final de tu compra.",
      question2: "¿Es seguro realizar el pago en nuestra tienda en línea?",
      answer2:
        "Absolutamente. Contamos con medidas de seguridad avanzadas para proteger tus datos y garantizar transacciones seguras. ¡Puedes comprar con confianza!",
      question3: "¿Cuál es el tiempo estimado de entrega?",
      answer3:
        "El tiempo de entrega será alrededor de 72 horas. Nosotros estaremos contactándonos contigo para informarte en caso de que exista alguna novedad con el despacho. ",
      question4: "¿Cómo hago el seguimiento de mi orden?",
      answer4:
        "Después de realizar tu compra, TRAMACO (nuestra empresa de distribución de pedido), se contactará contigo para poder entregar tu pedido.",
      question5: "¿Puedo cancelar mi pedido?",
      answer5:
        "Puedes contactarte con nosotros al siguiente correo electrónico: atencion.cliente@petgourmet.com.ec"
    }
  },
  cart: {
    title: "Carrito",
    subtotal: "Subtotal",
    total: "Total",
    product: "Producto",
    taxes: "Impuestos",
    add: "Añadir al carrito",
    added: "Estos son los productos que has agregado...",
    youOrder: "Tu orden",
    emptyTotal: "$0.00",
    emptyCart: "Vaciar carrito",
    continueAdd: "Continuar agregando al carrito",
    minimumAmountNotReached:
      "El monto mínimo de tu pedido debe ser de al menos",
    keepAddingProducts: "Sigue añadiendo para proceder con la compra"
  },
  checkout: {
    title: "Checkout",
    personalInfo: "Información personal",
    paymentMethod: "Métodos de pago",
    cashPayment: "Pago en efectivo",
    cardPayment: "Pago con tarjeta",
    cash: "Efectivo",
    card: "Tarjeta",
    status: {
      error: {
        text: "Hubo un problema al verificar el pago",
        tryAgain: "Volver a intentarlo"
      },
      empty: {
        title: "Aún no has agregado productos en tu carrito",
        description: "Explora nuestro menú y ¡Agrega productos!",
        buttonTitle: "Explorar menú"
      },
      success: "Tu pago se ha procesado con éxito",
      loading: {
        processing: "Estamos procesando tu pago...",
        creating: "Estamos creando el usuario",
        savingAddress: "Estamos guardando la dirección de entrega",
        savingBilling: "Estamos guardando los datos de facturación"
      }
    },
    anonymousTitle: "Quieres comprar más rápido",
    signIn: "Ingresa con tu cuenta",
    deliveryAddress: "Dirección de entrega",
    payExactValue: "Pagar con el valor exacto",
    howMuchPay: "¿Con cuánto vas a pagar?",
    enoughAmount: "El monto no es suficiente.",
    change: "Cambio",
    note: "Agrega una nota para este pedido",
    writeMessage:
      "Indicaciones de entrega, persona que lo recibe, horarios, etc.",
    billing: {
      title: "Datos de facturación",
      subTitle: "Añade datos de facturación",
      addData: "Añadir datos de facturación",
      billingExceded: "Límite de datos de facturación superado",
      onlyPossible: "Solo es posible tener",
      active: "datos de facturación activos"
    }
  },
  pickUpWorkflow: {
    store: "Tienda"
  },
  loyalty: {
    metaTitle: "Lealtad"
  },
  auth: {
    signIn: "Inicia sesión o accede con tus redes sociales",
    signUp: "Registrarse",
    alternativeSignIn: "¿Aún no tienes una cuenta?",
    accountQuestion: "¿Ya tienes una cuenta?",
    signInConfirmation: {
      title: {
        loading: "Ingresando...",
        success: "Ingreso exitoso",
        error: "Algo salió mal al intentar ingresar"
      }
    },
    signInRUC: {
      join: "Registra tu empresa",
      enterRUC: "Ingrese su RUC",
      RUC: "RUC",
      businessName: "Razón social"
    },
    signInForm: {
      emailQuestion: "O utilizando tu correo electrónico",
      forgotPasswordQuestion: "¿Olvidaste tu contraseña?",
      forgotPassword: "Olvidé mi contraseña",
      recoverPassword: "Recuperar contraseña"
    },
    signUpRUC: {
      enterRUC: "Registra tu empresa con tu RUC"
    },
    signUpUser: {
      oneMoreStep: "Un paso más",
      acceptTerms: "Acepto los ",
      terms: "términos y condiciones",
      privacy: "condiciones",
      infoTitle: "Toma en cuenta lo siguiente",
      infoDescription:
        "Si continuas vas a perder los datos asociados a tu identificador",
      errorMessage: "Ocurrió un problema, inténtelo nuevamente",
      fieldsError: "Revise los campos marcados en rojo",
      acceptEmail: "Acepto recibir correos electrónicos",
      requiredTerms: "Debes aceptar los términos y condiciones",
      and: " y ",
      acceptPrivacyPolicies: "He leído y acepto las políticas de privacidad ",
      configPrivacyPolicies: "configurar preferencias",
      consent: "Consiento el tratamiento de mis datos para: ",
      infoPrivacyPolicies:
        "Puedes retirar tu consentimiento y ejercer alguno de tus derechos siguiendo las instrucciones de las politicas. Para alguna pregunta o inquietud puedes comunicarte al siguiente correo: privacidad.datos@purenature.com",
      privacyPolicy: "Politicas Privacidad",
      privacyPolicyDescription:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora odio odit dolores? Explicabo ex autem vitae, consequuntur et alias, obcaecati rerum repudiandae assumenda deleniti culpa totam tempore mollitia nulla architecto. Lorem ipsum dolor sit amet consectetur      adipisicing elit. Officiis dolor quod libero soluta unde asperiores      vero ducimus natus impedit nulla, autem rem recusandae commodi      doloribus hic dolorem rerum totam dolore! Lorem ipsum dolor sit amet      consectetur adipisicing elit. Consequuntur eligendi fuga aliquid      aperiam repudiandae, eius ipsa nihil fugiat reiciendis recusandae      blanditiis totam nobis dolore aspernatur cumque mollitia amet laborum      neque?"
    },
    passwordStrength: {
      mustContain: "La contraseña debe contener:",
      capitalLetter: "Al menos una letra mayúscula",
      eightCharacters: "Al menos 8 personajes",
      oneNumber: "Al menos un número",
      specialCharacter: "Al menos un carácter especial"
    },
    singleSignOn: {
      selectRegister: "Selecciona como quieres registrarte",
      selectLogin: "Selecciona como quieres iniciar sesión",
      signInGoogle: "Acceder con Google",
      signInFacebook: "Acceder con Facebook",
      signInMagicLink: "Acceder con correo electrónico"
    },
    signOptions: {
      createAccountQuestion: "¿Aún no tienes una cuenta?",
      createAccount: "Crear cuenta",
      anonymousLogin: "Ingresa con tu cuenta"
    },
    recover: {
      enterEmail: "Ingresa tu correo electrónico",
      sendLink: "Enviaremos un link para que puedas iniciar sesión.",
      resend: "Volver a enviar",
      resendEmail: "Enviar nuevamente",
      wrongEmail: "¿Te equivocaste de correo?",
      editEmail: "Editar correo",
      sendEmail: "Hemos enviado un correo a:",
      changePassword:
        "Ahí encontrarás un enlace que te permitirá cambiar tu contraseña",
      recoveryEmail: "¿No has recibido el correo?",
      recoverTimer: "Volver a intentar en ",
      recoverSpamCheck: "Si no lo ves, revisa en tu carpeta de spam."
    },
    magic: {
      sendEmail: "Hemos enviado un correo a:",
      info: "Ahí encontrarás un enlace que te permitirá ingresar a tu cuenta.",
      enterEmail: "Ingresa tu correo electrónico",
      receivedEmail: "¿No has recibido el correo?",
      sendLink: "Enviaremos un link para que puedas iniciar sesión.",
      resendEmail: "Enviar nuevamente",
      spamCheck: "Si no lo ves, revisa en tu carpeta de spam."
    }
  },
  selectAddress: {
    delivery: "Entregar en",
    pickUp: "Recoger en",
    address: "Selecciona una dirección",
    store: "Selecciona una tienda",
    location: "Utilizar mi ubicación actual",
    nearestStore: "Selecciona tienda más cercana",
    errorUpdateAddress:
      "Se produjo un error al intentar actualizar los datos de la dirección",
    fillAddress: "Completa tu dirección",
    noCoverage: "Tu dirección está fuera de cobertura, intenta cambiarla",
    currentPosition: "Tu posición actual",
    editLocation: "Editar ubicación",
    deliveryAddress: "Dirección de entrega",
    locate: "Ubicarme en el mapa",
    empty: "No se encontraron resultados",
    findLocation: "Encuentra tu dirección",
    caveat1:
      "Por favor verifica sí tu dirección está ubicada dentro de una parroquia donde no exista cobertura para despachar tu pedido. Puedes confirmar haciendo un ",
    caveatClick: "clic aquí",
    caveat2:
      ". Si tienes dudas de la cobertura, comunícate con el equipo de soporte.",
    province: "Provincia",
    canton: "Cantón",
    parish: "Parroquia",
    selectProvince: "Selecciona tu provincia",
    selectCanton: "Selecciona tu cantón",
    selectParish: "Selecciona tu parroquia"
  },
  repeatOrder: {
    lastOrder: "Repetir último pedido",
    order: "Orden"
  },
  club: {
    join: "Únete a nuestro",
    club: "Club Pure Nature"
  },
  product: {
    about: "Sobre este artículo",
    recommended: "Recomendados para ti",
    promotions: "Promociones",
    purchaseWithPoints: "Compra este producto con tus puntos",
    availablePoints: "pts disponibles",
    limit:
      "Si quieres más del límite de unidades, por favor contacte a nuestro equipo de",
    shareProduct: "Compartir producto",
    shareWithAnother: "Comparte este producto con otra persona",
    support: "Soporte"
  },
  modifiers: {
    errorMin: "Elige al menos",
    errorMax: "Elige máximo",
    options: "opciones",
    description: "Selecciona mínimo 12 unidades",
    required: "Requerido",
    optional: "Opcional",
    perUnit: "c/u"
  },
  notFound: {
    title: "¡Página no encontrada!",
    description:
      "Al parecer ocurrió un error, vuelve a intentarlo o regresa al inicio.",
    back: "Ir al inicio"
  },
  searchBar: {
    recentSearch: "Búsquedas recientes",
    cleanHistory: "Limpiar historial"
  },
  clubBenefits: {
    title: "Disfruta de nuestros",
    benefits: "beneficios",
    subtitle: "Confirma los siguientes datos y ¡listo!",
    businessType: "Tipo de negocio",
    joinClub: "Unirme al Club Pure Nature"
  },
  privacyModal: {
    title: "Hemos actualizado nuestras Políticas de privacidad",
    paragraph1:
      "Manejamos la información que nos brindas para gestionar nuestras iniciativas comerciales y mantener una relación cercana con nuestros clientes.",
    paragraph2:
      "Queremos que sepas que tienes el derecho de acceder, actualizar, revocar, eliminar y oponerte al tratamiento de tus datos. Si deseas ejercer alguno de estos derechos, no dudes en enviarnos un correo electrónico a: atencion.cliente@petgourmet.com.ec Estaremos encantados de ayudarte en lo que necesites.",
    accept: "Aceptar"
  },
  deleteAccount: {
    errorDeleted: "No se pudo eliminar la cuenta",
    title: "Eliminar cuenta",
    question: "¿Seguro deseas eliminar tu cuenta?",
    description:
      "Una vez que elimines tu cuenta no podrás volver a recuperarla y deberás crear una cuenta nueva.",
    cancel: "Mantener mi cuenta",
    delete: "Eliminar mis datos"
  },
  notificationModal: {
    title: "Estás fuera de cobertura",
    description: "Intenta cambiar tu ubicación para continuar",
    changeLocation: "Cambiar de ubicación",
    change: "Cambiar"
  },
  cvvValidation: {
    title: "Por motivos de seguridad",
    description: "vuelve a ingresar el código CVV",
    validatePay: "Validar y pagar"
  },
  outService: {
    title: "estamos construyendo algo nuevo para ti",
    description: "Nos vemos pronto con todo lo que tu NEGOCIO necesita"
  }
};

export default es;
